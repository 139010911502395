<style lang="scss" scoped>
.page-stock-list {
    /deep/ .toolbars {
        .buttons {
            .mobile-group-button {
                padding-left: 9px;
                padding-right: 9px;
            }
        }
    }
    /deep/ .stock-content {
        .el-card__body {
            min-height: 560px;
        }
    }
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
}
.assets {
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
}
</style>

<template>
    <div class="page-stock-list">
        <div class="toolbars mrgb10">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button style="padding-left: 10px; padding-right: 10px;" class="mrgr5 mrgb5" size="medium" plain @click="_select()"><i class="el-icon-s-operation"></i> {{ warehouseName }}</el-button>
                </div>
                <div class="fl">
                    <el-button-group class="mrgb5" v-if="tabIndex !== null">
                        <el-button :class="$root.isPc ? '' : 'mobile-group-button'" type="primary" v-for="(tab, index) in tabs" :plain="defaultTabs[tabIndex].name !== tabs[index].name"
                            :key="'stock_tab_' + index" size="medium" @click="tabClick(index)">{{ tab.name }}</el-button>
                    </el-button-group>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div class="stock-content">
            <el-card v-if="tabIndex === 0">
                <!--库存列表-->
                <all-stock :ref="refAllStock"></all-stock>
            </el-card>
            <el-card v-if="tabIndex === 1">
                <!--入库-->
                <in-stock :ref="refInStock"></in-stock>
            </el-card>
            <el-card v-if="tabIndex === 2">
                <!--出库-->
                <out-stock :ref="refOutStock"></out-stock>
            </el-card>
            <el-card v-if="tabIndex === 3">
                <!--调库-->
                <transfer-stock :ref="refTransferStock"></transfer-stock>
            </el-card>
            <el-card v-if="tabIndex === 4">
                <!--更换-->
                <exchange-stock :ref="refExchangeStock"></exchange-stock>
            </el-card>
            <el-card v-if="tabIndex === 5">
                <!--报废-->
                <damage-stock :ref="refDamageStock"></damage-stock>
            </el-card>
            <el-card v-if="tabIndex === 6">
                <!--日志-->
                <logs :ref="refLogs"></logs>
            </el-card>
        </div>

        <!--事业部和仓库选择-->
        <condition :ref="refCondition" @refreshData="changeCondition"></condition>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import permission from "@/mixins/permission"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
import AllStock from "./allStockList"
import InStock from "./inStockBill"
import OutStock from "./outStockBill"
import TransferStock from "./transferStockBill"
import ExchangeStock from "./exchangeBill"
import DamageStock from "./damageBill"
import Logs from "./logs"
import Condition from "./components/condition"
import { getStockCompany, delStockCompany, getStockWarehourse, delStockWarehourse } from "@/utils/storages"
export default {
    name: "pageStockList",
    mixins: [enumConfigs, permission],
    components: { AllStock, InStock, OutStock, TransferStock, ExchangeStock, DamageStock, Logs, Condition },
    data() {
        return {
            refDetail: "refStockToDetail",
            refAllStock: "refStockToAllStock",
            refInStock: "refStockToInStock",
            refOutStock: "refStockToOutStock",
            refTransferStock: "refStockToTransferStock",
            refExchangeStock: "refStockToExchangeStock",
            refDamageStock: "refStockToDamageStock",
            refLogs: "refStockTrefLogs",
            refCondition: "refStockToCondition",
            tableData: [],
            total: null,
            listQuery: {
                ownerType: null,
                ownerId: "",
                companyId: "",
                categoryId: null,
                assetId: "",
                keywords: null,
                page: 1,
                size: window.$common.appPageSize
            },
            isPagination: false,
            assets: [],
            allWarehouse: [],
            warehouseName: "切换仓库",
            defaultTabs: [
                { name: "库存", value: "", audit: "isStockWarehouseStock" },
                { name: "入库", value: "In", audit: "isStockInbound" },
                { name: "出库", value: "Out", audit: "isStockOutbound" },
                { name: "调库", value: "Transfer", audit: "isStockTransfer" },
                { name: "更换", value: "Exchange", audit: "isStockExchange" },
                { name: "报废", value: "Damage", audit: "isStockDamage" },
                { name: "日志", value: "Log", audit: "isWarehouseStockChangeLogs" },
            ],
            tabs: [],
            tabIndex: 0
        }
    },
    watch: {
        "$store.getters.enumConfigs": {
            handler() {
                this.getAllWarehouse()
                this.changeCondition()
            },
            deep: true,
            immediate: false
        }
    },
    mounted() {
        if (this.$store.getters.enumConfigs && this.$store.getters.enumConfigs.AssetOwnerType) {
            this.getAllWarehouse()
        }
    },
    methods: {
        getCondition() {
            let back = false
            let companyItem = getStockCompany()
            let warehourseItem = getStockWarehourse()
            if (warehourseItem && warehourseItem.id) {
                let curWarehouse = this.allWarehouse.find(x => {
                    return x.id === warehourseItem.id
                })
                this.warehouseName = curWarehouse && curWarehouse.name ? `${curWarehouse.name} (切换)` : "切换仓库"
            } else {
                this.warehouseName = "切换仓库"
                delStockCompany()
                delStockWarehourse()
            }
            this.listQuery.companyId = companyItem && companyItem.id || ""
            this.listQuery.ownerId = warehourseItem && warehourseItem.id || ""
            if (this.listQuery.companyId && this.listQuery.ownerId) {
                back = true
            }
            return back
        },
        initData() {
            let back = this.getCondition()
            if (!back) {
                this._select()
            }
        },
        async getAllWarehouse() {
            this.getAuditTabs()
            await funInfomation.searchWarehouse({ companyId: this.listQuery.companyId }).then(res => {
                this.allWarehouse = res.items
            })
            this.initData()
        },
        getAuditTabs() {
            this.tabs = this.defaultTabs.filter(x => {
                return x.audit && this[x.audit]
            })
        },
        // 资产查询
        remoteMethod(v) {
            let params = {
                categoryId: null,
                keywords: v,
                takeNumber: 20
            }
            funInfomation.searchAsset(params).then(res => {
                this.assets = res.items
            })
        },
        _select() {
            this.$refs[this.refCondition].open()
        },
        tabClick(index) {
            let isOk = this.getCondition()
            if (isOk) {
                this.tabIndex = this.defaultTabs.findIndex(x => {
                    return x.name === this.tabs[index].name
                })
            } else {
                this._select()
            }
        },
        changeCondition() {
            let curIndex = this.tabIndex
            this.tabIndex = null
            setTimeout(() => {
                this.tabClick(curIndex || 0)
            }, 0)
        }
    }
}
</script>